new Vue({
    el: '#app',

    data: {
      url: {
        form: 'https://forms.gle/F5WHwSVysw4nhGuRA',
        line: 'line://ti/p/@bat168',
        wechat: 'https://u.wechat.com/IEutuK-O1iu5LBVZ-w0PVek'
      },
      'account': {
        bank: '中國信託（822）0935-4013-9545',
        line: '@bat168',
        wechat: 'nickairport'
      },
      year: new Date().getFullYear()
    },

    mounted: function() {
        init();
    }
  });

// Init
function init() {
    $(function() {
        handleCarousel();
        handlePageAnchor();
    });
}

// Carousel
function handleCarousel() {
    $('.js-carousel').slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true
    });
}

// Page anchor
function handlePageAnchor() {
    $('a[href^="#"]').on('click', function(e) {
        e.preventDefault();
        var target = $(this.getAttribute('href'));
        
        if (target.length) {
            var windowScrollTop = $(window).scrollTop();
            var scrollTop =  target.offset().top - 50;
            var seconds = windowScrollTop > scrollTop
                        ? (windowScrollTop - scrollTop) / 2
                        : (scrollTop - windowScrollTop) / 2;
            
            $('html, body').stop().animate({
                scrollTop: scrollTop
            }, seconds);
        }
    });
}